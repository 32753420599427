import React, { useState, useMemo, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { ExtraSmallOnly, HideExtraSmall, TYPE } from 'theme'
import Loader, { LoadingRows } from 'components/Loader'
import { Link } from 'react-router-dom'
import { AutoColumn } from 'components/Column'
import { RowFixed } from 'components/Row'
import { Label, ClickableText } from '../Text'
import { PageButtons, Arrow, Break } from 'components/shared'
import HoverInlineText from '../HoverInlineText'
import useTheme from 'hooks/useTheme'
import { AssetTokenData } from 'data/assetvault'
import { ethers } from 'ethers'
import { formatAmount, formatDollarAmount } from 'utils/numbers'

const Wrapper = styled.div`
  width: 100%;
`

const ResponsiveGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;

  grid-template-columns: 20px 1.5fr repeat(3, 1fr);

  @media screen and (max-width: 900px) {
    grid-template-columns: 20px 1.5fr repeat(3, 1fr);
    & :nth-child(4) {
      display: none;
    }
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: 20px 1.5fr repeat(2, 1fr);
    & :nth-child(6) {
      display: none;
    }
  }

  @media screen and (max-width: 670px) {
    grid-template-columns: repeat(2, 1fr);
    > *:first-child {
      display: none;
    }
    > *:nth-child(3) {
      display: none;
    }
  }
`

const DataRow = ({ tokenData, index }: { tokenData: AssetTokenData; index: number }) => {
  const theme = useTheme()

  return (
    // <LinkWrapper to={'tokens/' + tokenData.tokenAddress}>
      <ResponsiveGrid>
        <Label>{index + 1}</Label>
        <Label>
          {/* <RowFixed>
            <ResponsiveLogo address={tokenData.tokenAddress} />
          </RowFixed> */}
          <ExtraSmallOnly style={{ marginLeft: '6px' }}>
            <Label ml="8px">{tokenData.symbol}</Label>
          </ExtraSmallOnly>
          <HideExtraSmall style={{ marginLeft: '10px' }}>
            <RowFixed>
              <HoverInlineText text={tokenData.name} />
              <Label ml="8px" color={theme.text3}>
                ({tokenData.symbol})
              </Label>
            </RowFixed>
          </HideExtraSmall>
        </Label>
        <Label end={1} fontWeight={400}>
          {formatAmount(parseFloat(ethers.utils.formatUnits(tokenData.amount, tokenData.decimal)))}
        </Label>
        <Label end={1} fontWeight={400}>
          {parseFloat(ethers.utils.formatUnits(tokenData.debt, tokenData.decimal)).toFixed(2)}
        </Label>
        <Label end={1} fontWeight={400}>
          {tokenData.tvl ? formatDollarAmount(Number(tokenData.tvl)) : '-'}
        </Label>
        {/* <Label end={1} fontWeight={400}>
          {formatDollarAmount(tokenData.tvlUSD)}
        </Label> */}
      </ResponsiveGrid>
    // </LinkWrapper>
  )
}

const SORT_FIELD = {
  name: 'name',
  volumeUSD: 'volumeUSD',
  tvl: 'tvl',
  priceUSD: 'priceUSD',
  priceUSDChange: 'priceUSDChange',
  priceUSDChangeWeek: 'priceUSDChangeWeek',
}

const MAX_ITEMS = 10

export default function TokenTable({
  tokenDatas,
  maxItems = MAX_ITEMS,
}: {
  tokenDatas: AssetTokenData[] | undefined
  maxItems?: number
}) {
  // for sorting
  const [sortField, setSortField] = useState(SORT_FIELD.tvl)
  const [sortDirection, setSortDirection] = useState<boolean>(true)

  // pagination
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  useEffect(() => {
    let extraPages = 1
    if (tokenDatas) {
      if (tokenDatas.length % maxItems === 0) {
        extraPages = 0
      }
      setMaxPage(Math.floor(tokenDatas.length / maxItems) + extraPages)
    }
  }, [maxItems, tokenDatas])

  const sortedTokens = useMemo(() => {
    return tokenDatas
      ? tokenDatas
          .slice()
          .sort((a, b) => {
            if (a && b) {
              const status = sortField == "name" ? a[sortField as keyof AssetTokenData] > b[sortField as keyof AssetTokenData] :  parseFloat(a[sortField as keyof AssetTokenData]) > parseFloat(b[sortField as keyof AssetTokenData]);
              return status 
                ? (sortDirection ? -1 : 1) * 1
                : (sortDirection ? -1 : 1) * -1
            } else {
              return -1
            }
          })
          .slice(maxItems * (page - 1), page * maxItems)
      : []
  }, [tokenDatas, maxItems, page, sortField, sortDirection])

  const handleSort = useCallback(
    (newField: string) => {
      setSortField(newField)
      setSortDirection(sortField !== newField ? true : !sortDirection)
    },
    [sortDirection, sortField]
  )

  const arrow = useCallback(
    (field: string) => {
      return sortField === field ? (!sortDirection ? '↑' : '↓') : ''
    },
    [sortDirection, sortField]
  )

  if (!tokenDatas) {
    return <Loader />
  }

  return (
    <Wrapper style={{ marginTop: 16 }}>
      {sortedTokens.length > 0 ? (
        <AutoColumn gap="16px">
          <ResponsiveGrid>
            <Label color={'white'}>#</Label>
            <ClickableText color={'white'} onClick={() => handleSort(SORT_FIELD.name)}>
              Token {arrow(SORT_FIELD.name)}
            </ClickableText>
            <ClickableText color={'white'} end={1}/*  onClick={() => handleSort(SORT_FIELD.priceUSD)} */>
              Available Tokens
            </ClickableText>
            <ClickableText color={'white'} end={1}/*  onClick={() => handleSort(SORT_FIELD.priceUSDChange)} */>
              Borrowed Tokens
            </ClickableText>
            <ClickableText color={'white'} end={1} onClick={() => handleSort(SORT_FIELD.tvl)}>
              Total Vault Supply {arrow(SORT_FIELD.tvl)}
            </ClickableText>
            {/* <ClickableText color={'white'} end={1} onClick={() => handleSort(SORT_FIELD.tvlUSD)}>
              TVL {arrow(SORT_FIELD.tvlUSD)}
            </ClickableText> */}
          </ResponsiveGrid>

          <Break />
          {sortedTokens.map((data, i) => {
            if (data) {
              return (
                <React.Fragment key={i}>
                  <DataRow index={(page - 1) * MAX_ITEMS + i} tokenData={data} />
                  <Break />
                </React.Fragment>
              )
            }
            return null
          })}
          <PageButtons>
            <div
              onClick={() => {
                setPage(page === 1 ? page : page - 1)
              }}
            >
              <Arrow faded={page === 1 ? true : false}>←</Arrow>
            </div>
            <TYPE.body>{'Page ' + page + ' of ' + (maxPage === 0 ? 1 : maxPage)}</TYPE.body>
            <div
              onClick={() => {
                setPage(page === maxPage ? page : page + 1)
              }}
            >
              <Arrow faded={page === maxPage ? true : false}>→</Arrow>
            </div>
          </PageButtons>
        </AutoColumn>
      ) : (
        <LoadingRows>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </LoadingRows>
      )}
    </Wrapper>
  )
}
