import gql from 'graphql-tag'
import { useState, useEffect, useMemo } from 'react'
import { ethers } from 'ethers'
import { useActiveNetworkVersion, useDataClient } from 'state/application/hooks'
import UniswapV2VaultABI from 'constants/abis/UniswapV2Vault.json'
import { RPC_PROVIDERS, UNISWAP_V2_VAULT_ADDRESS } from 'constants/index'

export const ASSET_TOKENS = gql`
  query tokens {
    asTokens: assetTokens(orderBy: amount, orderDirection: desc) {
      id
      tokenAddress
      amount
      debt
    }
  }
`

export const TOKENS_QUERY = gql(`
  query tokens {
    tokens {
      decimal
      id
      name
      symbol
    }
  }
`)

export interface AssetToken {
  id: string
  tokenAddress: string
  amount: string
  debt: string
}

export interface TokenMetadata {
  address: string
  name: string
  decimal: string
  symbol: string,
  networkId: number
}

export interface AssetTokenData {
  id: string
  tokenAddress: string
  amount: string
  debt: string
  name: string
  decimal: string
  symbol: string
  tvl?: string
  networkId: number
}

interface TokenRes {
  asTokens: AssetToken[]
}

export function useAssetVaultData(): {
  tokens: AssetTokenData[]
  tvlFromAsset: number
  loading: boolean
} {
  const infoClient = useDataClient()
  const [activeNetwork] = useActiveNetworkVersion()
  const [availableTokens, setAvailableTokens] = useState<TokenMetadata[]>([])

  const [tokenData, setTokenData] = useState<TokenRes | undefined>()
  const [tvlFromAsset, setTvlFromAsset] = useState<number>(0)
  const [formattedTokens, setFormattedTokens] = useState<AssetTokenData[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  // fetch data based on search input
  useEffect(() => {
    async function fetch() {
      try {
        setAvailableTokens([])
        setTokenData(undefined)
        setFormattedTokens([])
        setLoading(true)
        const tokens = await infoClient.query<TokenRes>({
          query: ASSET_TOKENS,
        })

        if (tokens.data) {
          setTokenData(tokens.data)
        }

        const { data } = await infoClient.query({
          query: TOKENS_QUERY,
        })

        setAvailableTokens([
          ...data.tokens.map((token: any) => {
            return {
              address: token.id,
              name: token.name,
              decimal: token.decimal,
              symbol: token.symbol,
              networkId: activeNetwork.id,
            }
          }),
        ])
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }
    fetch()
  }, [infoClient, activeNetwork])

  const allFetchedTokens = useMemo(() => {
    if (tokenData && availableTokens.length > 0) {
      return [...tokenData.asTokens].map((token: AssetToken) => {
        const tokenMetadata = availableTokens.find((t) => t.address === token.tokenAddress)

        return {
          ...token,
          name: tokenMetadata?.name,
          symbol: tokenMetadata?.symbol,
          decimal: tokenMetadata?.decimal,
          networkId: tokenMetadata?.networkId,
        } as AssetTokenData
      })
    }
    return []
  }, [tokenData, availableTokens])

  // format as token and pool datas
  /* const { data: tokenFullDatas, loading: tokenFullLoading } = useFetchedTokenDatas(
    allFetchedTokens.map((t) => t.tokenAddress)
  ) */

  useEffect(() => {
    ;(async () => {
      const provider = new ethers.providers.JsonRpcProvider(RPC_PROVIDERS[activeNetwork.id])
      const contract = new ethers.Contract(UNISWAP_V2_VAULT_ADDRESS[activeNetwork.id], UniswapV2VaultABI, provider)
      let totalTvl = 0
      const _formattedTokens = []
      if (allFetchedTokens.length > 0) {
        for (const token of allFetchedTokens) {
          try {
            if (token.networkId === activeNetwork.id) {
              const tvl = await contract.convertCapitalToUSD(token.tokenAddress, token.amount + "0000")
              totalTvl += (tvl / 10000)
              _formattedTokens.push({
                ...token,
                tvl: (tvl / 10000).toString(),
              })
            }
          } catch (e) {
            console.error(e)
          }
        }
        setTvlFromAsset(totalTvl)
        setFormattedTokens(_formattedTokens)
      }
    })()
  }, [allFetchedTokens, activeNetwork])
  // const formattedTokens = useMemo(() => (tokenFullDatas ? Object.values(tokenFullDatas) : []), [tokenFullDatas])

  // const tvlFromAsset = useMemo(() => {
  //   let sum = 0
  //   allFetchedTokens.map((token) => {
  //     sum += Number(ethers.utils.formatUnits(token.amount, token.decimal))
  //   })
  //   return sum
  // }, [allFetchedTokens])

  return {
    tokens: formattedTokens,
    tvlFromAsset: tvlFromAsset,
    loading: loading,
  }
}
