import React, { useCallback, useState, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import Loader from 'components/Loader'
import { AutoColumn } from 'components/Column'
import { formatDollarAmount, formatAmount } from 'utils/numbers'
import { shortenAddress, getEtherscanLink } from 'utils'
import { Label, ClickableText } from 'components/Text'
import { Transaction, TransactionType } from 'types'
import { formatTime } from 'utils/date'
import { RowFixed } from 'components/Row'
import { ExternalLink, TYPE } from 'theme'
import { PageButtons, Arrow, Break } from 'components/shared'
import HoverInlineText from 'components/HoverInlineText'
import { GoerliNetworkInfo } from 'constants/networks'
import { useActiveNetworkVersion } from 'state/application/hooks'
import { ethers } from 'ethers'
import UniswapV2VaultABI from 'constants/abis/UniswapV2Vault.json'
import { UNISWAP_V2_VAULT_ADDRESS, RPC_PROVIDERS } from 'constants/index'

const Wrapper = styled.div`
  padding-top: 16px;
  width: 100%;
`

const ResponsiveGrid5 = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;

  grid-template-columns: 1.5fr repeat(5, 1fr);

  @media screen and (max-width: 940px) {
    grid-template-columns: 1.5fr repeat(5, 1fr);
    & > *:nth-child(5) {
      display: none;
    }
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: 1.5fr repeat(2, 1fr);
    & > *:nth-child(5) {
      display: none;
    }
    & > *:nth-child(3) {
      display: none;
    }
    & > *:nth-child(4) {
      display: none;
    }
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: 1.5fr repeat(1, 1fr);
    & > *:nth-child(5) {
      display: none;
    }
    & > *:nth-child(3) {
      display: none;
    }
    & > *:nth-child(4) {
      display: none;
    }
    & > *:nth-child(2) {
      display: none;
    }
  }
`

const SortText = styled.button<{ active: boolean }>`
  cursor: pointer;
  font-weight: ${({ active }) => (active ? 500 : 400)};
  opacity: ${({ active }) => (active ? 1 : 0.45)};
  margin-right: 0.75rem !important;
  border: none;
  background-color: transparent;

  background: linear-gradient(73.6deg, #85ffc4 2.11%, #5cc6ff 42.39%, #bc85ff 85.72%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: ${({ active }) => (active ? 'transparent' : '')};
  background-clip: text;
  text-fill-color: transparent;

  font-size: 18px;
  line-height: 28px;
  padding: 0px;
  color: ${({ active, theme }) => (active ? theme.text1 : 'white')};
  outline: none;
  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
`

const SORT_FIELD = {
  amountUSD: 'tvl',
  timestamp: 'timestamp',
  sender: 'sender',
  amountToken0: 'amountToken0',
  amountToken1: 'amountToken1',
  ammType: 'ammType',
}

const DataRow = ({ transaction, color }: { transaction: Transaction; color?: string }) => {
  const [tvl, setTvl] = useState(0)
  const [activeNetwork] = useActiveNetworkVersion()

  useEffect(() => {
    const getTvl = async () => {
      if (!transaction.tvl) {
        try {
          const provider = new ethers.providers.JsonRpcProvider(RPC_PROVIDERS[activeNetwork.id])
          const contract = new ethers.Contract(UNISWAP_V2_VAULT_ADDRESS[activeNetwork.id], UniswapV2VaultABI, provider)
          const tvl = await contract.convertCapitalToUSD(transaction.token0Address, transaction.amountToken0 + "0000")
          setTvl(Number(tvl / 10000))
        } catch (e) { 
        }
      }
    }
    getTvl()
  }, [transaction.amountToken0, transaction.tvl, transaction.token0Address, activeNetwork])

  return (
    <ResponsiveGrid5>
      <ExternalLink href={getEtherscanLink(activeNetwork.id, transaction.hash, 'transaction', activeNetwork)}>
        <Label color={'white'} fontWeight={400}>
          {transaction.type === TransactionType.DEPOSIT
            ? `Deposit ${transaction.token0Symbol}`
            : transaction.type === TransactionType.WITHDRAW
            ? `Withdraw`
            : transaction.type === TransactionType.BORROW
            ? `Borrow ${transaction.token0Symbol}`
            : transaction.type === TransactionType.REPAY
            ? `Repay ${transaction.token0Symbol}`
            : transaction.type === TransactionType.ADD_LIQUIDITY
            ? `Add Liquidity ${transaction.token0Symbol}/${transaction.token1Symbol}`
            : transaction.type === TransactionType.IMPORT_LIQUIDITY
            ? `Import Liquidity ${transaction.token0Symbol}/${transaction.token1Symbol}`
            : transaction.type === TransactionType.MIGRATE_LIQUIDITY
            ? `Migrate Liquidity ${transaction.token0Symbol}/${transaction.token1Symbol}`
            : `Remove Liquidity ${transaction.token0Symbol}/${transaction.token1Symbol}`}
        </Label>
      </ExternalLink>
      <Label end={1} fontWeight={400}>
        {formatDollarAmount(Number(transaction.tvl) || tvl)}
      </Label>
      <Label end={1} fontWeight={400}>
        <HoverInlineText
          text={`${formatAmount(
            Number(ethers.utils.formatUnits(transaction.type === TransactionType.ADD_LIQUIDITY ? (transaction.lpAmount || transaction.amountToken0) : transaction.amountToken0, transaction.type === TransactionType.ADD_LIQUIDITY ? 18 : transaction.token0Decimal))
          )}  ${
            transaction.type === TransactionType.ADD_LIQUIDITY
              ? 'LP'
              : transaction.token0Symbol
          }`}
          maxCharacters={16}
        />
      </Label>
      {/* <Label end={1} fontWeight={400}>
        <HoverInlineText text={`${formatAmount(abs1)}  ${transaction.token1Symbol}`} maxCharacters={16} />
      </Label> */}
      <Label end={1} fontWeight={400}>
        <ExternalLink
          href={getEtherscanLink(activeNetwork.id, transaction.sender, 'address', activeNetwork)}
          style={{ color: 'white' }}
        >
          {shortenAddress(transaction.sender)}
        </ExternalLink>
      </Label>
      <Label end={1} fontWeight={400}>
        {transaction.ammType || '-'}
      </Label>
      <Label end={1} fontWeight={400}>
        {formatTime(transaction.timestamp, /* activeNetwork === OptimismNetworkInfo ? 8 :  */ 0)}
      </Label>
    </ResponsiveGrid5>
  )
}

export default function TransactionTable({
  transactions,
  maxItems = 10,
  color,
}: {
  transactions: Transaction[]
  maxItems?: number
  color?: string
}) {
  // for sorting
  const [sortField, setSortField] = useState(SORT_FIELD.timestamp)
  const [sortType, setSortType] = useState("String")
  const [sortDirection, setSortDirection] = useState<boolean>(true)

  // pagination
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  // filter on txn type
  const [txFilter, setTxFilter] = useState<TransactionType[] | undefined>(undefined)

  useEffect(() => {
    let extraPages = 1
    const filtered = transactions.filter((x) => {
      return txFilter === undefined || txFilter.includes(x.type)
    });
    if (filtered.length % maxItems === 0) {
      extraPages = 0
    }
    setMaxPage(Math.floor(filtered.length / maxItems) + extraPages)
  }, [maxItems, transactions, txFilter])

  const sortedTransactions = useMemo(() => {
    return transactions
      ? transactions
          .slice()
          .sort((a, b) => {
            if (a && b) {
              return sortType == "String" ? a[sortField as keyof Transaction] > b[sortField as keyof Transaction] : parseFloat(a[sortField as keyof Transaction]) > parseFloat(b[sortField as keyof Transaction])
                ? (sortDirection ? -1 : 1) * 1
                : (sortDirection ? -1 : 1) * -1
            } else {
              return -1
            }
          })
          .filter((x) => {
            return txFilter === undefined || txFilter.includes(x.type)
          })
          .slice(maxItems * (page - 1), page * maxItems)
      : []
  }, [transactions, maxItems, page, sortField, sortType, sortDirection, txFilter])

  const handleSort = useCallback(
    (newField: string, type: string) => {
      setSortField(newField)
      setSortType(type);
      setSortDirection(sortField !== newField ? true : !sortDirection)
    },
    [sortDirection, sortField]
  )

  const arrow = useCallback(
    (field: string) => {
      return sortField === field ? (!sortDirection ? '↑' : '↓') : ''
    },
    [sortDirection, sortField]
  )

  if (!transactions) {
    return <Loader />
  }

  return (
    <Wrapper>
      <AutoColumn gap="16px">
        <RowFixed>
          <SortText
            onClick={() => {
              setPage(1);
              setTxFilter(undefined)
            }}
            active={txFilter === undefined}
          >
            All
          </SortText>
          <SortText
            onClick={() => {
              setPage(1);
              setTxFilter([TransactionType.DEPOSIT, TransactionType.WITHDRAW, TransactionType.BORROW, TransactionType.REPAY])
            }}
            active={
              txFilter
                ? txFilter?.includes(TransactionType.DEPOSIT) || txFilter?.includes(TransactionType.WITHDRAW) || txFilter?.includes(TransactionType.BORROW) || txFilter?.includes(TransactionType.REPAY)
                : false
            }
          >
            Asset Vault
          </SortText>
          <SortText
            onClick={() => {
              setPage(1);
              setTxFilter([TransactionType.ADD_LIQUIDITY, TransactionType.REMOVE_LIQUIDITY])
            }}
            active={
              txFilter
                ? txFilter?.includes(TransactionType.ADD_LIQUIDITY) ||
                  txFilter?.includes(TransactionType.REMOVE_LIQUIDITY)
                : false
            }
          >
            AMM Vault
          </SortText>
          <SortText
            onClick={() => {
              setPage(1);
              setTxFilter([TransactionType.IMPORT_LIQUIDITY, TransactionType.MIGRATE_LIQUIDITY])
            }}
            active={
              txFilter ? txFilter?.includes(TransactionType.IMPORT_LIQUIDITY) || txFilter?.includes(TransactionType.MIGRATE_LIQUIDITY) : false
            }
          >
            Migrations
          </SortText>
        </RowFixed>
        <ResponsiveGrid5>
          <ClickableText color={'white'} start={1}>
            Transaction
          </ClickableText>
          <ClickableText color={'white'} onClick={() => handleSort(SORT_FIELD.amountUSD, "Float")} end={1}>
            Total Value {arrow(SORT_FIELD.amountUSD)}
          </ClickableText>
          <ClickableText color={'white'} end={1}/*  onClick={() => handleSort(SORT_FIELD.amountToken0)} */>
            Token Amount {/* {arrow(SORT_FIELD.amountToken0)} */}
          </ClickableText>
          <ClickableText color={'white'} end={1} /*onClick={() => handleSort(SORT_FIELD.sender, "String")}*/>
            Wallet Address 
            {/* {arrow(SORT_FIELD.sender)} */}
          </ClickableText>
          <ClickableText color={'white'} end={1} /*onClick={() => handleSort(SORT_FIELD.ammType, "String")}*/>
            AMM 
            {/* {arrow(SORT_FIELD.ammType)} */}
          </ClickableText>
          <ClickableText color={'white'} end={1} onClick={() => handleSort(SORT_FIELD.timestamp, "Float")}>
            Time {arrow(SORT_FIELD.timestamp)}
          </ClickableText>
        </ResponsiveGrid5>
        <Break />

        {sortedTransactions.map((t, i) => {
          if (t) {
            return (
              <React.Fragment key={i}>
                <DataRow transaction={t} color={color} />
                <Break />
              </React.Fragment>
            )
          }
          return null
        })}
        {sortedTransactions.length === 0 ? <TYPE.main>No Transactions</TYPE.main> : undefined}
        <PageButtons>
          <div
            onClick={() => {
              setPage(page === 1 ? page : page - 1)
            }}
          >
            <Arrow faded={page === 1 ? true : false}>←</Arrow>
          </div>
          <TYPE.body>{'Page ' + page + ' of ' + (maxPage === 0 ? 1 : maxPage)}</TYPE.body>
          <div
            onClick={() => {
              setPage(page === maxPage ? page : page + 1)
            }}
          >
            <Arrow faded={page === maxPage ? true : false}>→</Arrow>
          </div>
        </PageButtons>
      </AutoColumn>
    </Wrapper>
  )
}
