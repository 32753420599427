import useTheme from 'hooks/useTheme'
import React, { useState, useRef } from 'react'
import { ChevronDown } from 'react-feather'
import { useActiveNetworkVersion } from 'state/application/hooks'
import styled from 'styled-components'
import { MEDIA_WIDTHS } from "theme";
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { CeloNetworkInfo, EthereumNetworkInfo, GoerliNetworkInfo, NetworkInfo, PolygonNetworkInfo, SUPPORTED_NETWORK_VERSIONS } from 'constants/networks'
import { useHistory } from 'react-router-dom'
require('./style.css');

const HeaderElement = styled.div`
  @media (max-width: 1030px) {
    align-items: center;
  }
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-left: 0.5em;
  }

  /* addresses safari's lack of support for "gap" */
  & > *:not(:first-child) {
    margin-left: 8px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    
  `};
`

const SelectorWrapper = styled.div`
  @media screen and (min-width: ${MEDIA_WIDTHS.upToSmall}px) {
    position: relative;
  }
`
const SelectorControls = styled.div<{ interactive: boolean }>`
  align-items: center;
  border-radius: 100px;
  color: ${({ theme }) => theme.text1};
  cursor: ${({ interactive }) => (interactive ? 'pointer' : 'auto')};
  display: flex;
  font-weight: 600;
  justify-content: space-between;
  height: 40px;
  width: 184px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  width:inherit;
 `};
  padding: 0 10px !important;
`

const Logo = styled.img`
  height: 32px;
  width: 32px;
  margin-right: 8px;
`

const NetworkLabel = styled.div`
  flex: 1 1 auto;
  color: white;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const SelectorLogo = styled(Logo) <{ interactive?: boolean }>`
  margin-right: ${({ interactive }) => (interactive ? 8 : 0)}px;
  @media screen and (min-width: ${MEDIA_WIDTHS.upToSmall}px) {
    margin-right: 8px;
  }
`

const SelectorLabel = styled(NetworkLabel)`
  display: none;
  color: white;
  text-align: left;
  @media screen and (min-width: ${MEDIA_WIDTHS.upToSmall}px) {
    display: block;
    width: 100px;
    margin-right: 8px;
  }
`

const FlyoutMenu = styled.div`
  background: linear-gradient(73.6deg, #85FFC4 2.11%, #5CC6FF 42.39%, #BC85FF 85.72%);
  border: none;
  padding: 1px;
  position: absolute;
  border-radius: 20px;
  min-width: 200px;
  z-index: 99;
  @media screen and (min-width: ${MEDIA_WIDTHS.upToSmall}px) {

  }
`
const FlyoutMenuContents = styled.div`
  align-items: flex-start;
  background-color: #16161F;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
  0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  overflow: auto;
  padding: 16px;

  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
`

const StyledChevronDown = styled(ChevronDown)`
  width: 16px;
  color: white;
`

const ActiveRowWrapper = styled.div`
  background-color: 'rgb(47 42 60)';
  border-radius: 8px;
  cursor: pointer;
  padding: 0px;
  width: 100%;
`

const FlyoutRow = styled.div<{ active: boolean }>`
  align-items: center;
  background-color: ${({ active, theme }) => (active ? 'rgb(47 42 60)' : 'transparent')};
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  justify-content: space-between;
  padding: 8px 8px;
  text-align: left;
  width: 100%;
`

export default function NetworkDropdown() {
  const [activeNetwork] = useActiveNetworkVersion()
  const history = useHistory()

  const [showMenu, setShowMenu] = useState(false)

  const node = useRef<HTMLDivElement>(null)
  useOnClickOutside(node, () => setShowMenu(false))
  const updateNetwork = (network: NetworkInfo) => {
    setShowMenu(false);
    history.push(`/${network.route}/`)
  };

  return (
    <HeaderElement>
      <SelectorWrapper ref={node as any} onMouseEnter={() => setShowMenu(true)} onMouseLeave={() => setShowMenu(false)}>
        <SelectorControls interactive className='select-network'>
          <SelectorLogo interactive src={activeNetwork.imageURL} />
          <SelectorLabel>{activeNetwork.name}</SelectorLabel>
          <StyledChevronDown />
        </SelectorControls>
        {showMenu && (
          <div style={{ paddingTop: '8px', position: 'absolute', width: '184px' }}>
            <FlyoutMenu>
              <FlyoutMenuContents>
                {
                  SUPPORTED_NETWORK_VERSIONS.map((network) => {
                    return (
                      <ActiveRowWrapper key={network.id}>
                        <FlyoutRow active={activeNetwork.id == network.id} onClick={() => updateNetwork(network)} >
                          <Logo src={network.imageURL} />
                          <NetworkLabel>{network.name}</NetworkLabel>
                        </FlyoutRow>
                    </ActiveRowWrapper>
                    )
                  })
                }
              </FlyoutMenuContents>
            </FlyoutMenu>
          </div>
        )}
      </SelectorWrapper>
    </HeaderElement>
  )
}
