import gql from 'graphql-tag'
import { useState, useEffect, useMemo } from 'react'
import { BigNumber } from 'ethers'
import { useDataClient } from 'state/application/hooks'

export const AMM_DATAS = gql`
  query tokens {
    asLiquidites: liquidities (where: {lpAmount_gt: 0})  {
      id
      owner
      asset
      capital
      lpAmount
      pair
      ammType
      bundle
      capitalAmount
      assetAmount
    }
    asMigrations: migrations (where: {status: "Active"}) {
      id
      owner
      asset
      capital
      pair
      capitalImported
      assetImported
      capitalReceived
      assetReceived
      fee
      lpAmount
      ammType
      bundle
      status
    }
  }
`

export const CAPITAL_DATA_QUERY = gql(`
  query {
    capiatalSupplies {
      ammVault
      amount
    }
  }
`)

export const TOKENS_QUERY = gql(`
  query tokens {
    tokens {
      decimal
      id
      name
      symbol
    }
  }
`)

export const CAPITA_SUPPLIES_QUERY = gql(`
  query {
    capitalSupplies {
      amount
      capital
      id
    }
  }
`)

export interface Liquidity {
  id: string
  owner: string
  asset: string
  capital: string
  lpAmount: string
  pair: string
  ammType: string
  bundle: string
  capitalAmount: string
  assetAmount: string
}

export interface Migration {
  id: string
  owner: string
  asset: string
  capital: string
  pair: string
  capitalImported: string
  assetImported: string
  capitalReceived: string
  assetReceived: string
  fee: string
  lpAmount: string
  ammType: string
  bundle: string
  status: string
}

export interface TokenMetadata {
  address: string
  name: string
  decimal: string
  symbol: string
}

export interface AMMData {
  id: string
  pairAddress: string
  amount: string
  assetAmount: string
  capitalAddress: string
  assetAddress: string
  capitalToken: {
    name: string | undefined
    symbol: string | undefined
    decimal: string | undefined
  }
  assetToken: {
    name: string | undefined
    symbol: string | undefined
    decimal: string | undefined
  }
  ammType: string
}

export interface PairData {
  id: string
  pairAddress: string
  amount: string
  assetAmount: string
  capitalAddress: string
  assetAddress: string
  capitalToken: {
    name: string | undefined
    symbol: string | undefined
    decimal: string | undefined
  }
  assetToken: {
    name: string | undefined
    symbol: string | undefined
    decimal: string | undefined
  }
  ammType: string
}

export interface CapitalData {
  id: string
  capital: string
  capitalToken: {
    name: string | undefined
    symbol: string | undefined
    decimal: string | undefined
  }
  amount: string
}

interface PoolRes {
  asLiquidites: Liquidity[]
  asMigrations: Migration[]
}

type SupplyRow = {
  ammVault: string
  amount: string
}

interface TransactionResults {
  capiatalSupplies: SupplyRow[]
}

export function useAMMVaultData(): {
  pools: AMMData[]
  pairs: PairData[]
  capital: CapitalData[]
  tvl: string
  loading: boolean
} {
  const infoClient = useDataClient()
  const [availableTokens, setAvailableTokens] = useState<TokenMetadata[]>([])
  const [poolData, setPoolData] = useState<PoolRes | undefined>()
  const [capitals, setCapitals] = useState<CapitalData[]>([])
  const [tvl, setTvl] = useState<string>('0')

  // fetch data based on search input
  useEffect(() => {
    async function fetch() {
      try {
        const pools = await infoClient.query<PoolRes>({
          query: AMM_DATAS,
        })

        if (pools.data) {
          setPoolData(pools.data)
        }

        const { data: ethData } = await infoClient.query<TransactionResults>({
          query: CAPITAL_DATA_QUERY,
          fetchPolicy: 'cache-first',
        })
    
        let sumOfEthAmount = BigNumber.from('0')
        if (ethData) {
          sumOfEthAmount = ethData.capiatalSupplies.reduce((acc, supply) => {
            return BigNumber.from(acc).add(BigNumber.from(supply.amount))
          }, BigNumber.from('0'))
        }  
        setTvl(sumOfEthAmount.toString())

        const supplies = await infoClient.query({
          query: CAPITA_SUPPLIES_QUERY,
        })

        setCapitals(supplies.data.capitalSupplies)

        const { data } = await infoClient.query({
          query: TOKENS_QUERY,
          fetchPolicy: 'cache-first',
        })

        setAvailableTokens([
          ...data.tokens.map((token: any) => {
            return {
              address: token.id,
              name: token.name,
              decimal: token.decimal,
              symbol: token.symbol,
            }
          }),
        ])
      } catch (e) {
        console.log(e)
      }
    }
    fetch()
  }, [infoClient])

  /* useEffect(() => {
    (async () => {
      const { data: ethData } = await infoEthereumClient.query<TransactionResults>({
        query: CAPITAL_DATA_QUERY,
        fetchPolicy: 'cache-first',
      })
  
      let sumOfEthAmount = BigNumber.from('0')
      if (ethData) {
        sumOfEthAmount = ethData.capiatalSupplies.reduce((acc, supply) => {
          return BigNumber.from(acc).add(BigNumber.from(supply.amount))
        }, BigNumber.from('0'))
      }

      const { data: celoData } = await celoClient.query<TransactionResults>({
        query: CAPITAL_DATA_QUERY,
        fetchPolicy: 'cache-first',
      })
  
      let sumOfCeloAmount = BigNumber.from('0')
      if (celoData) {
        sumOfCeloAmount = celoData.capiatalSupplies.reduce((acc, supply) => {
          return BigNumber.from(acc).add(BigNumber.from(supply.amount))
        }, BigNumber.from('0'))
      }

      const { data: auroraData } = await auroraClient.query<TransactionResults>({
        query: CAPITAL_DATA_QUERY,
        fetchPolicy: 'cache-first',
      })
  
      let sumOfAuroraAmount = BigNumber.from('0')
      if (auroraData) {
        sumOfAuroraAmount = auroraData.capiatalSupplies.reduce((acc, supply) => {
          return BigNumber.from(acc).add(BigNumber.from(supply.amount))
        }, BigNumber.from('0'))
      }
      setCapitalData({
        ETH: sumOfEthAmount.toString(),
        CELO: sumOfCeloAmount.toString(),
        AURORA: sumOfAuroraAmount.toString(),
      })
    })()
  }, []) */

  const allFetchedTokens = useMemo(() => {
    if (poolData && availableTokens.length > 0) {
      return Object.values([...poolData.asLiquidites].reduce((acc: Record<string, any>, liquidity: Liquidity) => {
        const capital = liquidity.capital > liquidity.asset ? liquidity.capital : liquidity.asset;
        const asset = liquidity.capital > liquidity.asset ? liquidity.asset : liquidity.capital;
        const capitalAmount =  liquidity.capital > liquidity.asset ? liquidity.capitalAmount : liquidity.assetAmount;
        const assetAmount =  liquidity.capital > liquidity.asset ? liquidity.assetAmount : liquidity.capitalAmount;
        const capitalToken = availableTokens.find((t) => t.address === capital)
        const assetToken = availableTokens.find((t) => t.address === asset)
    
        if (!acc[liquidity.pair]) {
          acc[liquidity.pair] = {
            id: liquidity.id,
            pairAddress: liquidity.pair,
            capitalAddress: capital,
            assetAddress: asset,
            capitalToken: {
              name: capitalToken?.name || '',
              symbol: capitalToken?.symbol || '',
              decimal: capitalToken?.decimal || '',
            },
            assetToken: {
              name: assetToken?.name,
              symbol: assetToken?.symbol,
              decimal: assetToken?.decimal,
            },
            amount: capitalAmount,
            assetAmount: assetAmount,
            ammType: liquidity.ammType,
          }
        } else {
          acc[liquidity.pair].amount = (parseFloat(acc[liquidity.pair].amount) + parseFloat(capitalAmount)).toString();
          acc[liquidity.pair].assetAmount = (parseFloat(acc[liquidity.pair].assetAmount) + parseFloat(assetAmount)).toString();
        }
    
        return acc;
      }, {}));    
    }
    return []
  }, [poolData, availableTokens])

  const allFetchedMigrations = useMemo(() => {
    if (poolData && availableTokens.length > 0) {
      if (poolData.asMigrations.length === 0) {
        return []
      }
      return Object.values([...poolData.asMigrations].reduce((acc: Record<string, any>, migration: Migration) => {
        const capitalToken = availableTokens.find((t) => t.address === migration.capital)
        const assetToken = availableTokens.find((t) => t.address === migration.asset)
        const key = `${migration.capital}${migration.asset}`;
    
        if (!acc[key]) {
          acc[key] = {
            id: migration.id,
            pairAddress: migration.pair,
            capitalAddress: migration.capital,
            assetAddress: migration.asset,
            capitalToken: {
              name: capitalToken?.name || '',
              symbol: capitalToken?.symbol || '',
              decimal: capitalToken?.decimal || '',
            },
            assetToken: {
              name: assetToken?.name,
              symbol: assetToken?.symbol,
              decimal: assetToken?.decimal,
            },
            amount: migration.capitalImported || '0',
            assetAmount: migration.assetImported || '0',
            ammType: migration.ammType,
          }
        } else {
          acc[key].amount = (parseFloat(acc[key].amount) + parseFloat(migration.capitalImported || '0')).toString();
          acc[key].assetAmount = (parseFloat(acc[key].assetAmount) + parseFloat(migration.assetImported || '0')).toString();
        }
    
        return acc;
      }, {}));
    }
    return []
  }, [poolData, availableTokens])

  const allFetchedCapital = useMemo(() => {
    return capitals.map((capital) => {
      const capitalToken = availableTokens.find((t) => t.address === capital.capital)
      return {
        id: capital.id,
        capital: capital.capital,
        capitalToken: {
          name: capitalToken?.name || '',
          symbol: capitalToken?.symbol || '',
          decimal: capitalToken?.decimal || '',
        },
        amount: capital.amount,
      }
    })
  }, [capitals, availableTokens])

  return {
    pools: allFetchedTokens,
    pairs: allFetchedMigrations,
    capital: allFetchedCapital,
    tvl: tvl,
    loading: false,
  }
}
